app-list-view app-template-preview > .column > .header {
    display: none;
}

app-template-form {

    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    background-color: var(--body--background-color);

  .modal .header > a {
    @include flex;
    align-items: center;
    min-height: var(--minHitArea);
  }

  .template-options-wrapper {
    overflow-x: auto;
    padding: var(--space--smallest) var(--space--smallest) 3rem 0;
    display: flex;
  }

  --templateOptions--columns--count : 3;
  --templateOptions--columns--size : 10rem;
  --templateOptions--rows--count : 2;
  --templateOptions--rows--size : auto;

  .template-options {
    display: grid;
    gap: var(--space--smaller);
    display: grid;
    grid-template-columns: repeat(#{var(--templateOptions--columns--count)}, minmax(var(--templateOptions--columns--size), 1fr));
    grid-template-rows: repeat(#{var(--templateOptions--rows--count)}, minmax(auto, var(--templateOptions--rows--size)));
  }

  .clump.is-placeholder {
    border-radius: var(--border-radius);
    background-color: rgba(0,0,0,.03);
    border: dashed 2px $border-color--black--light;
    color: var(--interaction);
    box-shadow: none;
  }

  .template-option {
    @include hoverEffect(color);
    border-radius: var(--border-radius);
    background-color: rgba(0,0,0,.03);
    border: dashed 2px $border-color--black--light;
    color: var(--interaction);
    font-size: var(--font-size--small);
    min-height: 12rem;
    padding: var(--space--small);
    & > .button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      border: none;
      flex-flow: column
    }
    &.is-valid {
      @include alertColors("success");
      border-color: transparent;
      // padding: var(--space--small);
      cursor: unset
    }
    &.is-touched {
      padding: var(--space--small);
    }
    &:not(.is-valid) {
      @include flex;
      justify-content: center;
      align-items: center;
    }

  }

  .template-enhancement,
  .template-discount,
  .proposal-discount {
    &.is-valid {
      &:hover, &:focus {
        background-color: #569735 !important;
      }
    }
  }

  .template-enhancement {
    .template-enhancement-image {
      background-repeat: no-repeat;
      background-size: cover;
      height: auto;
      width: 6rem;
      background-position: center;
    }
  }

  .list.list--static > .list-item.u-colors--success,
  .list.list--static > .list-item.u-colors--success:hover {
    @include alertColors(success)
  }

  .list-item {
    .beans {
      margin-left: calc(var(--space--smaller) * -1);
    }
    .bean {
      &:not(.is-active) {
        background-color: transparent;
        border: $border-color--white--light;
        @include opacity(light)
      }
      &.is-active {
        background-color: rgba(255,255,255,.9);
        color: var(--alert--success)
      }
    }

    .list-item-compatabilities {
      display: grid;
      gap: var(--space--smaller);
      grid-template-columns: 4rem 4rem 4rem;
      grid-template-rows: 2rem 2rem;
      // grid-template-areas:
      //   "a b c ."
      //   "first-a first-b first-c first"
      //   "second-a second-b second-c second"
        // grid-template-areas:
        //   "td-0 td-1 td-2"
        //   "td-3 td-4 td-5"
    }
  }

  .modal {
    &.optionModal {
      height: clamp(75.8rem, 87vh, 90rem);
      width: clamp(20rem, 84rem, 120rem);
      max-width: calc(100vw - var(--space--small) * 2);
      max-height: 96vh;
      min-height: 0;

      @media (max-width: 550px) {
        width: 100vw;
      }

      @media (max-height: 700px) {
        height: 100vh;
      }

        // tweak focus styling on segmented button form elements
      .button-group input[type="radio"]:hover:not([disabled]):not(:checked) + label, 
      .button-group input[type="radio"]:focus:not([disabled]):not(:checked) + label, 
      .button-group input[type="radio"]:hover:not([disabled]):not(.is-selected) + label, 
      .button-group input[type="radio"]:focus:not([disabled]):not(.is-selected) + label {
        outline: solid .2rem var(--interaction--light)
      }

    }

    .drawer {
      width: 100%;
      border-radius: 0;
    }

    .formTable {
      overflow-x: auto;

      &-headers {
        position: sticky;
        top: 0;
        background-color: #FFF;
        z-index: 1;
      }

      &-headers,
      &-size {
        gap: var(--space--small);
        padding: var(--space--smaller) var(--space--small);
        width: 100%;
        min-width: fit-content;
      }

      .formTable-size.is-disabled {
        background-color: rgba(0,0,0,.04);
        input:not([type='checkbox']) {
          @include opacity(disabled);
          pointer-events: none;
        }
        button { opacity: 0;}
      }

      .formTableCell {
        flex-shrink: 0;
        &--tons { 
          width: 7.2rem;
          label { padding-right: 0; }
        }
        &--smaller { width: 6.5rem }
        &--medium { width: 10rem }
        input {
          padding: var(--space--smaller) var(--space--smaller);
        }
      }

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      input[type=number] {
        -moz-appearance: textfield;
      }

    }

  }


}


  :root {
    --proposalGrid--columns--count : 3;
    --proposalGrid--columns--min-width : 32rem;
    --proposalGrid--columns--max-width : 32rem;
    --proposalGrid--rows--count : 2;
    --proposalGrid--rows--size : auto;

    
    --proposalView--sidePadding: 2.5rem;
    
    @media (min-width: 800px) {
      --proposalView--sidePadding: 3rem }

    @media (min-width: 1200px) {
      --proposalView--sidePadding: 5rem }

    @media (min-width: 1500px) {
      --proposalView--sidePadding: 10rem;
    }

    @media (min-width: 1800px) {
      --proposalView--sidePadding: 15vw }


    // @media (min-width: 2000px) {
    //   --proposalView--sidePadding: 20rem;
    // }


    // @media (min-width: 2300px) {
    //   --proposalView--sidePadding: 25rem;
    // }
  
  }

  .proposalView {

    &-banner {
      flex-shrink: 0;
      padding: var(--space--small) var(--proposalView--sidePadding);
      box-shadow: 0 0 0.2rem 0.1rem rgba(0, 0, 0, 0.12);
    }
    &-header {
      background-color: #fff;
      box-shadow: 0 0 .3rem .1rem rgba(0, 0, 0,.1);
      position: relative;
      padding: var(--space--large) var(--proposalView--sidePadding) var(--space--largest) var(--proposalView--sidePadding);
      app-selected-system {
        border: solid 2px var(--branding--secondary);
        background-color: #f5f5f6;
        // .job-system-title { color: var(--branding--secondary) }
        .titles + img {
          transform: translateX(1.5rem) translateY(-.6rem);
        }
      }
      .proposalView-paymentChoice { margin-bottom: auto }
    }
    .proposalOptions {
      display: flex;
      flex-flow: column;

      &-brand {
        background: linear-gradient(180deg, rgba(0,0,0,0) 75%, rgba(0,0,10,.02) 100%);
        padding: 6rem 0 10rem 0;
        border-bottom: solid 1px rgba(0,0,0,.1);

        & > .mat-accordion > .mat-expansion-panel > .mat-expansion-panel-header  {
          padding: 0 var(--proposalView--sidePadding);
          margin-bottom: var(--space);
        }
        &-options {
          padding: var(--space) var(--space) var(--space) var(--proposalView--sidePadding);
          // padding-bottom: var(--space--large);
          // padding-bottom: 20rem;
          overflow-x: auto;
        }
      }
    }
    &-enhancements {
      // background-color: rgba(0,0,10,.025);
      // padding: var(--space--large) var(--padding--variable);
      // background-color: rgb(221, 222, 225);
      // padding: var(--space--large) var(--padding--variable);
      // box-shadow: inset 0 1rem .6rem -1rem #0000;
      background-color: #eaeaec;
      padding: var(--space--larger) var(--proposalView--sidePadding);
      // box-shadow: inset 0 1rem .3rem -1rem #00000069;
    }
    &-footer {
      border-top-width: 1px;
    }
  }

  .proposalOptions-grid {
    // padding: var(--space--large) var(--padding--variable);
    // padding: 0 var(--padding--variable) var(--space--largest) var(--padding--variable);
    display: grid;
    gap: var(--space--smaller);
    grid-template-columns: repeat(#{var(--proposalGrid--columns--count)}, minmax(var(--proposalGrid--columns--min-width), var(--proposalGrid--columns--max-width)));
    grid-template-rows: repeat(#{var(--proposalGrid--rows--count)}, minmax(auto, var(--proposalGrid--rows--size)));
  }
